import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    hasAccesToInvoice: boolean = false;
    modelStore: MenuItem[] = [];
    modelSiege: MenuItem[] = [];

    finalMenuItemsCollections: MenuItem[] = [];

    @Input() userConnected: any;
    isStore: boolean | undefined;

    ngOnInit() {
        let userGroupsStringCollections: string[] = [];
        if (this.userConnected) {
            this.userConnected.userGroups.map(
                (_: any) => {
                    if (_) {
                        userGroupsStringCollections.unshift(_.displayName);
                    }
                }
            );
        }
        if (userGroupsStringCollections.includes('Invoice Management') || userGroupsStringCollections.includes('Invoice Management')) { this.hasAccesToInvoice = true; } else { this.hasAccesToInvoice = false; }
            this.modelStore = [
                {
                    label: "Nouvelle Demande",
                    fragment: 'assets/demo/images/new-purchase.png',
                    routerLink: ['/app-layout/supplier-operations/new-purchase-request/search-employee']
                }
                ,
                {
                    label: 'Suivi Demandes',
                    fragment: 'assets/demo/images/purchases.png',
                    routerLink: ['/app-layout/supplier-tracking/requests']
                },
                {
                    label: 'Reporting',
                    fragment: 'assets/demo/images/valid-purchases.png',
                    routerLink: ['/app-layout/supplier-tracking/reporting']
                },
                // ,
                // {
                //     label: "Suivi Dérogation",
                //     fragment: 'assets/demo/images/question.png',
                //     items: [
                //         {
                //             label: 'Suivi des demandes',
                //             icon: 'pi pi-fw pi-table',
                //             routerLink: ['/app-layout/supplier-exceptionnal-authorization/exceptionnal-requests']
                //         }
                //         ,
                //         {
                //             label: 'Reporting',
                //             icon: 'pi pi-fw pi-check-square',
                //             routerLink: ['/app-layout/supplier-exceptionnal-authorization/exceptionnal-reporting']
                //         }
                //     ]
                // }
            ];
            this.modelSiege = [
                {
                    label: 'Suivi Demandes',
                    fragment: 'assets/demo/images/purchases.png',
                    routerLink: ['/app-layout/supplier-tracking/requests']
                }
                ,
                {
                    label: 'Reporting',
                    fragment: 'assets/demo/images/valid-purchases.png',
                    routerLink: ['/app-layout/supplier-tracking/reporting']
                }
                ,
                {
                    label: 'Suivi Factures',
                    visible: this.hasAccesToInvoice,
                    fragment: 'assets/demo/images/invoice.png',
                    routerLink: ['/app-layout/supplier-invoice/home']
                }
                // ,
                // {
                //     label: "Dérogation",
                //     fragment: 'assets/demo/images/question.png',
                //     routerLink: ['/app-layout/supplier-exceptionnal-authorization/exceptionnal-requests']
                // }
            ]; 
        (userGroupsStringCollections.includes('Siege')) ? this.finalMenuItemsCollections = this.modelSiege : this.finalMenuItemsCollections = this.modelStore;
    }   
}
