import { PrimeIcons } from 'primeng/api';
import { Component, OnInit } from "@angular/core";
import { LayoutService } from "./service/app.layout.service";
import { UserAdService } from '../main/service/user-ad.service';
import { NotifierService } from './service/signal-r/notifier.service';
import { NotifierDataService } from './service/signal-r/notifier-data.service';

@Component({
    selector: 'app-Notifications-sidebar',
    templateUrl: './app.notifications-sidebar.component.html'
})
export class AppNotificationsSidebarComponent implements OnInit {

    date: Date = new Date();
    timelineEvents: any[] = [];

    userAD: any;

    constructor(
        private _userAD: UserAdService,
        private _notifData: NotifierDataService,
        private _notifierService: NotifierService,
        public layoutService: LayoutService) {
        this._userAD.connectedUserAD$
            .subscribe(
                {
                    next: (value: any) => {
                        this.userAD = value;
                    }
                }
            );
    }

    getShortName(name: string) {
        return name.split(' ').map(n => n[0]).join('');
    }

    ngOnInit(): void {
        // this._notifierService.startConnection();
        // this._notifData.notifs$.subscribe(
        //     {
        //         next: (newest_notif: any) => {
        //             if (newest_notif) {
        //                 let _adap = {
        //                     transaction: newest_notif.contentNotif,
        //                     icon: PrimeIcons.SHOPPING_BAG,
        //                     iconColor: '#FC6161',
        //                     amountColor: '#FC6161',
        //                     date: newest_notif.sendedAt,
        //                     amount: 100

        //                 };
        //                 debugger
        //                 this.timelineEvents.push(_adap);
        //             }
        //         }
        //     }
        // );
    }

    get visible(): boolean {
        return this.layoutService.state.notificationSidebar;
    }

    set visible(_val: boolean) {
        this.layoutService.state.notificationSidebar = _val;
    }

}
