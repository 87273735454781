<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-28rem"
    [showCloseIcon]="false"
>
    <div
        class="layout-rightmenu overflow-x-hidden"
        [ngClass]="{
            'layout-rightmenu-active': layoutService.state.notificationSidebar
        }"
    >
        <div class="flex justify-content-between align-items-center py-1 px-2">
            <div class="flex justify-content-center gap-1 align-items-center">
                <div class="profile-item topbar-item">
                    <div *ngIf="userAD" style="cursor: pointer !important">
                        <p-avatar
                            *ngIf="userAD?.companyShortName === 'HA'"
                            label="{{ userAD?.companyShortName }}"
                            styleClass=" mr-2 prevent-select"
                            size="large"
                            [style]="{
                                color: '#ffffff',
                                background: '#ef4b24'
                            }"
                        ></p-avatar>
                        <p-avatar
                            *ngIf="userAD?.companyShortName === 'ZEN'"
                            label="{{ userAD?.companyShortName }}"
                            styleClass="  mr-2 prevent-select"
                            size="large"
                            [style]="{
                                color: '#ffffff',
                                background: '#060606'
                            }"
                        ></p-avatar>
                        <p-avatar
                            *ngIf="userAD?.companyShortName === 'TTD'"
                            label="{{ userAD?.companyShortName }}"
                            styleClass=" mr-2 prevent-select"
                            size="large"
                            [style]="{
                                color: '#ffffff',
                                background: '#b7cd3b'
                            }"
                        ></p-avatar>
                        <p-avatar
                            *ngIf="userAD?.companyShortName === 'EXIST'"
                            label="EXI"
                            styleClass=" mr-2 prevent-select"
                            size="large"
                            [style]="{
                                color: '#ffffff',
                                background:
                                    'linear-gradient(326deg, #272822 0%, #da2d2d 74%)'
                            }"
                        ></p-avatar>
                        <p-avatar
                            *ngIf="userAD?.companyShortName === 'Sotufab'"
                            label="SOT"
                            styleClass=" mr-2 prevent-select"
                            size="large"
                            [style]="{
                                color: '#ffffff',
                                background:
                                    'linear-gradient(319deg, #ba2d0b 0%, #fe7f2d 37%, #ffbf46 100%)'
                            }"
                        ></p-avatar>
                    </div>
                </div>
                <div class="profile-item topbar-item">
                    <a class="cursor-pointer">
                        <p
                            class="font-semibold m-0"
                            style="
                                line-height: 0.7;
                                font-size: 1.15rem !important;
                            "
                        >
                            {{ userAD?.name }}
                        </p>
                        <span class="font-normal text-sm">
                            {{ userAD?.jobTitle }} &nbsp;|&nbsp;<span
                                class="font-normal"
                                >{{ userAD?.location }}</span
                            >
                        </span>
                    </a>
                </div>
            </div>
            <p-button
                icon="pi pi-times"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
                (onClick)="visible = false"
                pTooltip="Fermer"
                tooltipPosition="left"
            ></p-button>
        </div>

        <div class="mt-3 flex justify-content-center">
            <h4 class="text-700">Centre des notifications</h4>
        </div>
        <div class="user-detail-wrapper text-center">
            <div
                class="user-tasks flex justify-content-between align-items-center py-1 px-5 surface-border"
            >
                <div class="user-tasks-item in-progress font-medium">
                    <a
                        class="task-number text-red-500 flex justify-content-center align-items-center border-round"
                        style="
                            background: rgba(255, 255, 255, 0.05);
                            font-size: 30px;
                        "
                        >23</a
                    >
                    <span class="task-name">Non Lu</span>
                </div>
                <div class="user-tasks-item font-medium">
                    <a
                        class="task-number flex justify-content-center align-items-center border-round"
                        style="
                            background: rgba(255, 255, 255, 0.05);
                            font-size: 30px;
                        "
                        >6</a
                    >
                    <span class="task-name">Lu</span>
                </div>
                <div class="user-tasks-item font-medium">
                    <a
                        class="task-number flex justify-content-center align-items-center border-round"
                        style="
                            background: rgba(255, 255, 255, 0.05);
                            font-size: 30px;
                        "
                        >38</a
                    >
                    <span class="task-name">All</span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card p-0">
                <div
                    class="timeline-header p-3 flex justify-content-between align-items-center"
                >
                    <p class="m-0 font-medium text-primary">
                        Historique des notifications
                    </p>
                    <div class="header-icons">
                        <i class="pi pi-refresh ml-2"></i>
                        <i class="pi pi-filter ml-2"></i>
                    </div>
                </div>
                <div class="timeline-content pb-3">
                    <p-timeline
                        [value]="timelineEvents"
                        styleClass="custom-timeline py-0 px-3"
                    >
                        <ng-template pTemplate="marker" let-event>
                            <span
                                class="custom-marker border-circle p-1 w-2rem h-2rem flex justify-content-center align-items-center"
                                [style.backgroundColor]="event.iconColor"
                            >
                                <i [ngClass]="event.icon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <div
                                class="flex align-items-center justify-content-between"
                            >
                                <p class="m-0 font-medium">
                                    {{ event.transaction }}
                                </p>
                                <h6
                                    class="m-0"
                                    [style.color]="event.amountColor"
                                >
                                    {{ event.amount }}
                                </h6>
                            </div>
                            <span class="text-base text-color-secondary">{{
                                event.date
                            }}</span>
                        </ng-template>
                    </p-timeline>
                </div>
                <div
                    class="timeline-footer border-top-1 surface-border p-3 flex align-items-center justify-content-center"
                >
                    <a
                        href="#"
                        class="text-primary hover:text-primary-400 transition-duration-200"
                        >View all transactions <i class="pi pi-arrow-down"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>
