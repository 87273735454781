
import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const auth_sso_configuration: Configuration = {
    auth: {
        clientId: 'fc6b1664-6176-4ad4-8f6f-e343b2214bb3',
        authority: 'https://login.microsoftonline.com/20923150-f613-4b38-af27-a5dd1cb27af1',
        redirectUri: '/signin-oidc',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
        secureCookies: true
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                // console.log(logLevel, message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const AUTH_SSO_MSAL = {
    protectedResourceMap: new Map([
        ['https://prodconvamisupplierapi.azurewebsites.net/*', ['api://34391c83-35b3-4edb-90ed-28b0b3e5c44f/access_as_user']],
        ['https://devconvamisupplierapi.azurewebsites.net/*', ['api://34391c83-35b3-4edb-90ed-28b0b3e5c44f/access_as_user']],
        ['https://intconvamisupplierapi.azurewebsites.net/*', ['api://34391c83-35b3-4edb-90ed-28b0b3e5c44f/access_as_user']],
        ['http://localhost:4200/*', ['api://34391c83-35b3-4edb-90ed-28b0b3e5c44f/access_as_user']]
    ]),
}

