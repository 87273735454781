<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-28rem"
>
    <div
        class="layout-rightmenu h-full overflow-y-auto overflow-x-hidden"
        [ngClass]="{
            'layout-rightmenu-active': layoutService.state.rightMenuActive
        }"
    >
        <div
            class="user-detail-wrapper text-center"
            style="padding: 4.5rem 0 2rem 0"
        >
            <div class="user-detail-content mb-4">
                <img
                    src="assets/demo/images/utilisateur.png"
                    alt="atlantis"
                    class="user-image"
                    width="100"
                />
                <span class="user-name text-2xl text-center block mt-4 mb-1">{{
                    userLoggedIn?.name
                }}</span>
                <span class="user-number"
                    >{{ userLoggedIn?.location }}&nbsp;|&nbsp;{{
                        userLoggedIn?.jobTitle
                    }}</span
                >
            </div>
            <div
                class="user-tasks flex justify-content-center align-items-center py-4 border-bottom-1 surface-border"
            >
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Me Déconnecter"
                    icon="pi pi-power-off"
                    iconPos="right"
                    aria-label="Déconnexion"
                    class="p-button-raised p-button-danger"
                    pTooltip="Déconnecter"
                    tooltipPosition="bottom"
                    id="eex"
                    (click)="logout($event)"
                ></button>

                <p-confirmPopup key="confirm"></p-confirmPopup>
            </div>
        </div>
        <div>
            <p-calendar
                [(ngModel)]="date"
                [inline]="true"
                styleClass="w-full p-0"
            ></p-calendar>
        </div>
    </div>
</p-sidebar>
