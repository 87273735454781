import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LogLevel } from '@microsoft/signalr';
import { NotifierDataService } from './notifier-data.service';
import { PushNotificationsService } from '../browser-notifier/push-notifications.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    private hubConnection: signalR.HubConnection | undefined;
    SONG: string = "../../../assets/layout/notif_alert.mp3";
    ringtone: any;

    constructor(
        private _notificationService: PushNotificationsService,
        private _notifData: NotifierDataService) {
        this._notificationService.requestPermission();
        this.ringtone = new Audio();
        this.ringtone.src = this.SONG;
        this.ringtone.load();
    }

    public startConnection = () => {

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(`${environment.nativeApiUrl}supplierNotifier`, { withCredentials: false })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection
            .start()
            .then(
                () => {
                    console.log('Connection started | connectionId:' + ' ' + this.hubConnection?.connectionId);
                }
            )
            .catch(err => {
                console.log('Error while starting connection: ' + err);
            });

        this.hubConnection.on('Notify', (_notif) => {
            if (_notif) {
                let _newest: any = {
                    notif_id: _notif.idNotif,
                    environementSender: _notif.environementSender,
                    environementReceiver: _notif.environementReceiver,
                    sendedAt: _notif.sendedAt,
                    sender: _notif.sender,
                    contentNotif: _notif.contentNotif,
                    contextNotif: _notif.contextNotif
                };
                this._notifData.pushNotif(_newest);
                this.ringtone.play();
                this.Notify(_newest);
            }
        });

    }

    Notify(notif: any) {
        let data: Array<any> = [];
        data.push({
            'title': 'SUPPLIER V² | ' + notif.notif_id,
            'alertContent': notif.contentNotif
        });
        this._notificationService.generateNotification(data);
    }

}
