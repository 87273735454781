import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ForbiddenInterceptorService implements HttpInterceptor {

    constructor(private _router: Router) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    debugger
                    if (error.error instanceof ErrorEvent) {
                        console.log('This is client side error');
                        errorMsg = `Error: ${error.error.message}`;
                        if (error.status === 403 || error.status === 401) {
                            this._router.navigateByUrl('/access-denied');
                        }
                    } else {
                        console.log('This is server side error');
                        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                        if (error.status === 403 || error.status === 401) {
                            this._router.navigateByUrl('/access-denied');
                        }
                    }
                    console.log(errorMsg);
                    return throwError(() => errorMsg);
                })
            )
    }
}


