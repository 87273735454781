import { Component, Input } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MsalService } from '@azure/msal-angular';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {
    date: Date = new Date();

    @Input() userLoggedIn: any;

    constructor(
        private auth: MsalService,
        private confirmationService: ConfirmationService,
        public layoutService: LayoutService) { }

    get visible(): boolean {
        return this.layoutService.state.rightMenuActive;
    }

    set visible(_val: boolean) {
        this.layoutService.state.rightMenuActive = _val;
    }

    logout($event: Event) {
        this.confirmationService.confirm({
            key: 'confirm',
            target: $event.target || new EventTarget,
            message: "Êtes-vous sûr d'effectuer cette action ?",
            icon: 'pi pi-question',
            acceptLabel: "Confirmer",
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            rejectLabel: 'Annuler',
            acceptIcon: 'pi pi-thumbs-up-fill',
            rejectIcon: 'pi pi-times-circle',
            accept: () => {
                this.auth.logout();
            },
            reject: () => {
                return;
            }
        });
    }
}
