import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OfflineInterceptor implements HttpInterceptor {

    constructor(private _router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!navigator.onLine) {
            const errorMsg = 'You are offline. Please check your internet connection.';
            console.error(errorMsg);
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 0) {
                    const errorMsg = 'Could not connect to the server. Please try again later.';
                    console.error(errorMsg);
                    this._router.navigateByUrl('/offline-exception');
                }
                return throwError(error);
            })
        );
    }
}
