import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { ConfirmationService } from 'primeng/api';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { EventMessage, InteractionStatus } from '@azure/msal-browser';
import { UserAdService } from '../main/service/user-ad.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
    providers: [ConfirmationService]
})
export class AppTopbarComponent implements OnInit {

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('searchinput') searchInput!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    searchActive: boolean = false;

    isIframe = false;
    isLoggedIn = false;

    userAd: any;

    private readonly _destroying$ = new Subject<void>();
    private unsubscriber: Subject<void> = new Subject<void>();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public layoutService: LayoutService,
        private auth: MsalService,
        private confirmationService: ConfirmationService,
        public el: ElementRef,
        private _userAd: UserAdService
    ) {
        this._userAd.connectedUserAD$.subscribe(
            (userLoggedIn: any) => {
                this.userAd = userLoggedIn;
            }
        );
    }

    ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;
        this.authService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                } else {
                    this.setLoginDisplay();
                }
            }
            );
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe((userAD) => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
    }

    setLoginDisplay() {
        this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        let activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    getShortName(name: string) {
        return name.split(' ').map(n => n[0]).join('');
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }
    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    toggleSidebarNotification() {
        this.layoutService.showSidebarNotification();
    }

    logout($event: Event) {
        this.confirmationService.confirm({
            key: 'confirm',
            target: $event.target || new EventTarget,
            message: "Êtes-vous sûr d'effectuer cette action ?",
            icon: 'pi pi-question',
            acceptLabel: "Confirmer la déconnexion",
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            rejectLabel: 'Annuler',
            acceptIcon: 'pi pi-thumbs-up-fill',
            rejectIcon: 'pi pi-times-circle',
            accept: () => {
                this.auth.logout();
            },
            reject: () => {
                return;
            }
        });
    }
}
