import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotifierDataService implements OnDestroy {

    private _notifs: any = null;
    private notifs = new BehaviorSubject<any>(this._notifs);
    notifs$ = this.notifs.asObservable();

    pushNotif(notif: any) {
        this._notifs = notif;
        this.notifs.next(notif);
    }

    private _unsubscribeAll: Subject<any>;

    constructor(
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }

}
